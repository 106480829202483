
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import supportService from './../../../services/support'
import commonServices from '../../../services/common'

interface Support {
  email: string;
  message: string;
  title: string;
  status: string;
  profile: string;
}
export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const helpCenterFormRef = ref()
    const helpCenterRules = validate.helpCenterValidation
    const profile = commonServices.getCurrentProfile()
    const loading = ref<boolean>(false)
    const helpCenterForm: UnwrapRef<Support> = reactive({
      email: profile.email,
      message: '',
      title: '',
      status: 'Pending',
      profile: profile._id
    })
    const resetForm = () => {
      helpCenterFormRef.value.resetFields()
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const addTicket = async (data: Support) => {
      try {
        loading.value = true
        await supportService.addTicket(data)
        resetForm()
        await notify('Success', 'Your ticket submitted successfully', 'success')
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      helpCenterFormRef.value
        .validate()
        .then(() => {
          addTicket(helpCenterForm)
        })
        .catch((error: ValidateErrorEntity<Support>) => {
          console.log('error', error)
        })
    }
    return {
      helpCenterFormRef,
      helpCenterForm,
      helpCenterRules,
      validateForm,
      loading
    }
  }
})
