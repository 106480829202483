<template>
  <div class="main">
    <div class="Help-center content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Settings</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Help Center</h4>
        </a-col>
      </a-row>
      <div class="body">
        <h4>
          If You need any help, please use this form to tell as about your problem.
        </h4>
        <a-form ref="helpCenterFormRef" :model="helpCenterForm" :rules="helpCenterRules" :hideRequiredMark="true" layout="vertical" :wrapper-col="{ span: 24 }" :label-col="{ span: 24 }">
          <a-form-item label="Title" has-feedback name="title">
            <a-input type="text" size="large" placeholder="Ticket Title" v-model:value="helpCenterForm.title" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="Email" has-feedback name="email">
            <a-input type="text" size="large" placeholder="Enter Email" v-model:value="helpCenterForm.email" allow-clear autocomplete="off" disabled />
          </a-form-item>
          <a-form-item label="Message" has-feedback name="message">
             <a-textarea  allow-clear  placeholder="Type..." v-model:value="helpCenterForm.message" />
          </a-form-item>
        </a-form>
        <a-button type="primary" class="updateBtn" :loading="loading" @click.prevent="validateForm">Send Support Ticket</a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import supportService from './../../../services/support'
import commonServices from '../../../services/common'

interface Support {
  email: string;
  message: string;
  title: string;
  status: string;
  profile: string;
}
export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const helpCenterFormRef = ref()
    const helpCenterRules = validate.helpCenterValidation
    const profile = commonServices.getCurrentProfile()
    const loading = ref<boolean>(false)
    const helpCenterForm: UnwrapRef<Support> = reactive({
      email: profile.email,
      message: '',
      title: '',
      status: 'Pending',
      profile: profile._id
    })
    const resetForm = () => {
      helpCenterFormRef.value.resetFields()
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const addTicket = async (data: Support) => {
      try {
        loading.value = true
        await supportService.addTicket(data)
        resetForm()
        await notify('Success', 'Your ticket submitted successfully', 'success')
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      helpCenterFormRef.value
        .validate()
        .then(() => {
          addTicket(helpCenterForm)
        })
        .catch((error: ValidateErrorEntity<Support>) => {
          console.log('error', error)
        })
    }
    return {
      helpCenterFormRef,
      helpCenterForm,
      helpCenterRules,
      validateForm,
      loading
    }
  }
})
</script>

<style lang="scss">
.Help-center {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
      h4 {
      color: #707070;
      font-family: "TT Norms Pro Regular";
      font-size: 16px;
      text-align: left;
      margin: 0px 0 20px 0;
    }
    .ant-input-affix-wrapper{
          border-radius: 5px;
    }
    .has-error .ant-form-explain {
      text-align: left;
    }
    .ant-form {
      width: 55%;
      margin: 50px auto;
      label{
            color: #646464 !important;
      }
    }
    textarea.ant-input{
      min-height: 150px;
    }
    .updateBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      width: 250px;
      margin: 0 0 30px 0;
    }
  }
}
</style>
