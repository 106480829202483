import axios from 'axios'

export default {
  getTickets () {
    return axios.get(process.env.VUE_APP_SUPPORT_TICKET)
  },

  addTicket (data) {
    return axios.post(process.env.VUE_APP_SUPPORT_TICKET, data)
  }
}
